/*****************************************************************/
/***************************** FONTS *****************************/
/*****************************************************************/

@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "CairoRegular";
  src: local("CairoRegular"),
    url("./fonts/Cairo/Cairo-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Bravary";
  src: local("Bravary"), url("./fonts/Bravary/Bravary.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "BabyQueen";
  src: local("BabyQueen"), url("./fonts/Baby/Baby-Queen.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "LetsGo";
  src: local("LetsGo"), url("./fonts/LetsGo/letsgo.otf") format("truetype");
  font-weight: 500;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Koulen&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

/*****************************************************************/
/************************** Variable *****************************/
/*****************************************************************/

:root {
  --my-color: #f76c6f;
  --main-color: #18829b;
  --hover-color: #07677d;
  --second-color: #f07080;
  --hover-second: #813e46;
  --text-color: #333;
}

/*****************************************************************/
/**************************** GLOBAL *****************************/
/*****************************************************************/

#root {
  /* position: relative; */
  /* margin-top: 80px;
  min-height: calc(100vh - 80px); */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "RobotoRegular", sans-serif;
  background-color: #f7f7f7;
  background-attachment: fixed;
  background-size: cover;
}

body.arabic {
  font-family: "RobotoRegular", sans-serif;
}

.App {
  overflow: hidden;
}

svg {
  font-size: 1.5rem;
}

img {
  max-width: 100%;
}


h1,
h2,
h3 {
  font-family: "Courgette", sans-serif;
  color: #505251;
}

h1 {
  color: #505251;

  font-size: 3rem;
  /* font-family: 'LetsGo', Arial, Helvetica, sans-serif; */
}

h2 {
  color: #111;
  font-size: 2.2rem;
}

h3 {
  color: #222;
  font-size: 1.8rem;
  font-family: Cambria;
}

p {
  color: #333;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
}

// button {
//   width: fit-content;
//   background-image: none;
//   text-transform: uppercase;
//   letter-spacing: 3px;
//   font-size: 1rem;
//   font-weight: 600;
//   margin: auto;
//   padding: 5px 15px;
//   border-radius: 20px;
//   // background: var(--main-color);

//   color: #e7e7e7;
//   cursor: pointer;
//   border: none;
//   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
//   transition: all 0.5s;
// }

// button:hover {
//   background: var(--hover-color);
// }

a,
a:hover {
  text-decoration: none;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

thead tr {
  background-color: rgba(172, 169, 169, 0.4);
}

thead th {
  font-weight: bold !important;
}

#header.shadow {
  background-color: #000;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

/*****************************************************************/
/**************************** FOOTER *****************************/
/*****************************************************************/

footer p a {
  color: var(--my-color);
}

.socials {
  max-width: 30%;
}

.socials img {
  width: 40px;
  margin: 0 5px;
  transition: all 0.5s;
  opacity: 0.8;
}

.socials img:hover {
  opacity: 1;
}

.admin-media {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.admin-logo {
  width: 50px;
}

.modal-container {
  width: 100%;
  height: 100%;
  transform: scale(0);
  opacity: 0;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
  overflow-y: scroll;
}

.modal-content {
  padding: 3rem;
  margin: 3rem;
  border: 3px solid #000;
}

.modal-container.show {
  transform: scale(1);
  opacity: 1;
  z-index: 1000;
}

.modal-container.order-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  padding-top: 15px;
}

.modal-box {
  position: relative;
  width: 100%;
  min-height: 100%;
  background-color: #ddd;
  overflow: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 20%);
  padding: 4rem;
}

.modal-box.order-modal {
  background-color: #16081a;
}

.modal-box.confirmation-box {
  width: 35%;
  min-height: 30vh;
}

.confirmation-content {
  padding: 10px;
}

.confirmation-content h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.confirmation-content p {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}

.confirmation-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.confirmation-actions button {
  margin-left: 15px;
}

#close-modal {
  position: absolute;
  top: 2%;
  right: 2%;

  cursor: pointer;
  border: none;
  background: transparent;
  padding: 10px 0 0;
  margin: 0;
  box-shadow: none;
}

#close-modal svg {
  width: 30px;
  height: 30px;
  color: #333;
  transition: all 0.5s;
}

#close-modal:hover svg {
  color: #000;
  transform: scale(1.2);
}

.modal-header {
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #f76c6f;
    font-family: "LetsGo", Arial, Helvetica, sans-serif;
  }

  h2 {
    margin: 3rem 0 2rem 0;
    color: #333;
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.modal-header.order-modal {
  padding: 10px 20px 40px;
}

.modal-header.order-modal button svg {
  color: #999 !important;
}

.modal-header.order-modal button:hover svg {
  color: #fff !important;
}

.modal-header.order-modal h2 {
  color: #fff;
}

.form-center {
  display: flex;
  justify-content: center;
}

.form-center form {
  width: 100%;
}

.mybtn {
  width: 250px;
  margin: 15px auto !important;
}

.modal-form {
  padding: 20px 20px;
}

.modal-form .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.modal-form .form-group label {
  flex: 1;
  font-size: 15px;
  font-weight: 500;
}

.modal-form .form-group input {
  flex: 2;
  padding: 8px 15px;
  border-radius: 5px;
  border: 1px solid #888;
  background: white;
}

.modal-form form:after {
  content: "";
  clear: both;
}

.modal-form .submit-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-modal-content p {
  color: #fff;
}

.confirm-command {
  padding: 8px 15x;
  margin: 0 0 0 auto;
  font-size: 15px;
}

#feedback {
  padding-top: 20px;
  transition: all 0.5s;
  color: #da5555e3;
}

#feedback.show {
  opacity: 0;
  transform: scaleX(1);
}

#feedback svg {
  display: inline-block;
  margin: 0 10px 0 5px;
  width: 15px;
  height: 15px;
}

.not-found {
  padding: 60px 5%;
}

.cat-image {
  position: relative;
  height: 140px !important;
  width: 140px !important;
  border-radius: 50%;
  margin-left: 40px;
}

.selected-file {
  height: 180px !important;
  width: 180px !important;
  object-fit: cover;
  border-radius: 50%;
}

.photos-collections {
  padding: 5px;
  width: 100%;
  border: 1px dashed rgb(158, 127, 41);
  height: 90px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.collection {
  position: relative;
  height: 100% !important;
  width: 19% !important;
  border-radius: 50%;
  margin: 0 0.5%;
}

.collection-file {
  width: 100%;
  height: 80px;
}

.selected-file {
  width: 100%;
  height: 140px;
  margin: 3px;
  object-fit: cover;
}

.table-catImg {
  display: block;
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #eee;
  border: 2px solid #eee;
}

.paper-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paper-header .button-container button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.mytitle {
  color: #333;
  padding-left: 10px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-bottom: 40px;
}

.admin-menu a,
.admin-menu svg {
  color: #fff;
}

.loading {
  position: absolute;
  top: 50%;
  left: 48%;
}

.loading svg {
  color: rgb(131, 102, 62);
  font-size: 4rem;
}

.loading img {
  width: 50px;
}

.drawer-btn svg {
  color: #fff;
}

.crud-actions svg {
  font-size: 1.6rem;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.crud-actions svg:hover {
  transform: scale(1.15);
}

// .crud-actions svg:first-of-type {
//   color: #080;
// }

// .crud-actions svg:last-of-type {
//   color: #f00;
// }

.table-actions svg {
  font-size: 1.6rem;
  margin: 0 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.edit-icon {
  color: #080;
}

.delete-icon {
  color: #f00;
}

.public-icon {
  color: rgba(73, 73, 187, 0.808);
}

.unpublic-icon {
  color: rgba(173, 173, 201, 0.781);
}

.freez-icon {
  color: rgba(147, 147, 180, 0.63);
}

.unfreez-icon {
  color: rgba(31, 31, 226, 0.808);
}

.flex-imageBox {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

//! -------------- Category Page --------------- |

.categoryPage{
strong {
  font-size: 1.4rem;
}
}

.categoryRow,
.admin-row {
  background-color: hsla(191, 73%, 35%, 0.4);
}

.subCategory,
.prestataire-row {
  background-color: rgba(24, 130, 155, 0.4);
}

.client-row {
  background-color: rgba(182, 222, 231, 0.39);
}

.client-row.freezed {
  background-color: #003cff6e;
}

.subCategory td {
  font-size: 1.1rem;
  padding: 5px 5px 5px 35px;
}

.subCategory td:last-of-type {
  padding: 5px 5px 5px 16px;
}

.subCategory .table-catImg {
  width: 40px;
  height: 40px;
}

.alert {
  text-align: center;
}

.loading-img {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.cat-order,
.subcat-order {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cat-order {
  background-color: var(--hover-second);
}

.subcat-order {
  background-color: var(--hover-color);
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.col-2 {
  width: 48%;
  margin-bottom: 20px;
}

.form-center .col-2 > div {
  width: 100%;
  margin-bottom: 20px;
}

#input-avatar-native,
#input-photos-native,
#input-file-native {
  display: none;
}

.user-role {
  padding: 3px 6px;
  background-color: rgba(190, 136, 17, 0.6);
  border-radius: 40px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.status {
  padding: 4px 8px;
  border-radius: 40px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Courier New", Courier, monospace;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: bold;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.status.waiting {
  background-color: rgba(85, 92, 97, 0.432);
}

.status.public {
  background-color: rgba(16, 141, 16, 0.459);
}

.status.archived {
  background-color: rgba(158, 71, 12, 0.459);
}

.status.admin {
  background-color: rgba(182, 164, 64, 0.562);
}

.status.prestataire {
  background-color: rgba(165, 102, 180, 0.562);
}

.status.client {
  background-color: rgba(141, 182, 216, 0.562);
}

.alert-left .alert {
  text-align: left;
}

.flex-column {
  flex-direction: column;
}

.categories-container {
  border: 1px dashed rgb(158, 127, 41);
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  height: 115px;
  overflow-y: auto;
}

.categories-container > div {
  margin: 5px;
}

.categories-container .category {
  width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  border-radius: 40px;
  font-weight: bold;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.5s;
}

.categories-container .category:hover {
  background-color: rgba(24, 130, 155, 0.6);
}

.categories-container .category.active {
  background-color: rgba(24, 130, 155, 0.6);
}

.rotate {
  animation: rotate 25s linear infinite;
}

.category-item {
  aspect-ratio: 1/1;
  margin: 3rem auto;
  width: 260px;
  box-shadow: rgba(150, 201, 252, 0.3) 0px 8px 24px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.react-multi-carousel-track {
  cursor: grab;
}

.category-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.category-item .item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 25px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.likes-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
}

.likes-cell svg {
  color: #aa656d;
}

.spacer {
  width: 100%;
  height: 60px;
}

.like-button {
  position: absolute !important;
  top: 10px;
  right: 5px;
  background-color: transparent;
  background: none;
  border: none;
  box-shadow: none;
  z-index: 10;
  cursor: pointer;
}

.like-button svg {
  color: #df3347;
  font-size: 1.8rem !important;
}

.like-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.post-adress {
  width: 85%;
}

.post-description {
  height: 40px;
}

.post-description p {
  color: #666;
  font-size: 13px;
}

.post-price {
  font-size: 15px;
  font-weight: bold;
  padding-top: 4px;
}

.post-line {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.view-container {
  display: block;
  width: 100px;
  height: 100px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -100px;
  right: -100px;
  transform: rotate(45deg);
  opacity: 0;
  transition: all 0.5s;
}

.view {
  color: var(--my-color);
  position: absolute;
  bottom: 38px;
  right: 70px;
  transition: all 1s;
  transform: rotate(120deg);
  opacity: 0;
}

.post-hover:hover .view-container {
  bottom: -50px;
  right: -50px;
  opacity: 1;
}

.post-hover:hover .view {
  transform: rotate(315deg);
  opacity: 1;
}

.post-categories {
  max-width: 80%;
  height: 25px;
  line-height: 1.2;
  text-decoration: none;
}

.post-categories a {
  color: var(--my-color);
}

.post-date {
  color: #fff;
  font-size: 13px;
  position: absolute;
  left: 15px;
  top: 27px;
}

.post-svg-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.top-btn {
  cursor: pointer;
  color: #f5970a;
  transition: all 0.5s;
}

.top-btn:hover {
  transform: scale(1.2);
}

.page-header {
  height: 300px;
  position: relative;
}

.page-header .couverture-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.page-header .avatar-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  position: absolute;
  left: calc(50% - 60px);
  border-radius: 50%;
  bottom: -75px;
  border: 2px solid #eee;
}

.header-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  padding-top: 250px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content h2 {
  color: #fff;
}

.mycontainer {
  width: 90%;
  max-width: 1040px;
  margin: 0 auto;
}

.prestataire-content {
  min-height: calc(100vh - 300px);
}

.categories-sketlon {
  border-radius: 50%;
  overflow: hidden;
}

.text-white {
  color: #fff;
}

.paper-header .button-container button {
  background: var(--my-color);
}

.paper-header .mytitle {
  margin-bottom: 0;
}

.templates-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.templates-btn button {
  font-size: 15px;
  margin: 0 10px;
}

/***************************************************/
/******************** Animation ********************/
/***************************************************/

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}

/***************************************************/
/******************** ScrollBar ********************/
/***************************************************/

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--main-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--second-color);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--hover-second);
}

.addPostWindow {
  position: fixed;
  inset: 0;
  background-color: #fff;
  z-index: 10000;
  transform: scale(1.1);
}

// . {
//   min-height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
// }

.main-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 3rem;
  border: 10px solid #9570e6;
}

.main-container h1 {
  font-family: Roboto;
  font-weight: lighter;
}
.main-container p {
  line-height: 1.4rem;
}

.main-container .submit-button {
  display: block;
  color: white;
  padding: 8px 20px;
  font-weight: lighter;

  margin: auto;
  margin-top: 1rem;
  background-color: #9570e6;
}

//! ------------ Post detail page -------------- |

.postDetailPage {
  min-height: 100vh;
  .paper {
    padding: 3rem;
    width: 40%;
    margin: 2rem auto;

    // display: flex;
    // align-items: center;
    h1 {
      margin: 10px;
    }

    .event-p {
      font-size: 1.5rem;
      padding-left: 1rem;
      border-left: 1px solid #9570e6;
      line-height: 1.5rem;
    }

    .date-address-container {
      font-size: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      height: 100%;
    }
  }
}

.text-over-image {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 0 0 10px #000;
  p,
  h1,
  h2,
  svg {
    color: #fff;
  }

  svg {
    margin-right: 5px;
  }
}
.carousel-container {
  padding: 1rem;
  // display: flex;
  // justify-content: center;
}

.carousel-image-container {
  margin: 1rem;
  position: relative;

  .time {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
  }
}

.carousel-image {
}

.flexAIC {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flex {
  display: flex;
  gap: 1rem;
}

//! --------------- Profile Page ---------------- |

/* #region  ProfilePage */
.profilePage {
  .form-container {
    label {
      font-size: 20px;
      margin-bottom: 1rem;
    }
    padding: 3rem;

    .center {
      display: flex;
      gap: 3rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
}

.delete-image {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  color: white;
  background-color: #000;
  border-radius: 50%;

  width: 24px;
  height: 24px;

  cursor: pointer;
}

/* #endregion */

//! ------------ Add Post Admin -------------- |

.addPostAdmin {
  padding: 3rem;
  margin: 3rem;
  border: 3px solid #000;
}

//! ------------- My Reservation --------------- |

.MyReservationPage {
  padding: 3rem;

  .gradient {
    background: rgb(250, 154, 161);
    background: linear-gradient(
      90deg,
      rgba(250, 154, 161, 1) 0%,
      rgba(126, 149, 162, 1) 100%
    );

    transition: 1s ease-in-out;

    &:hover {
      filter: brightness(1.2);
    }
  }

  .title {
    display: inline-block;

    padding: 0.8rem;
    padding-right: 10rem;

    font-family: "Inter";
    font-size: 2rem;
    font-weight: bold;
    color: white;
    font-style: italic;

    border-radius: 10px 0 0 10px;

    clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
  }
}

//! -------------- Judge dialog ---------------- |

.judge-dialog {
  border-radius: 50%;
  border: 3px solid red;
  display: table;
  margin: auto;
  padding: 1.5rem;
}

//! ----------- reservation Request ------------- |

.reservationRequest {
  background-color: #f2f4ff;
  border-radius: 1rem;
  max-height: 600px;
  // overflow-y: scroll;

  * {
    font-weight: normal;
    font-family: inter;
  }

  h3 {
    font-size: 35px;
    background-color: #98aab5;
    border-radius: 1rem;
    text-align: center;
    padding: 6px;
    color: white;
  }
  span {
    // margin-top: 1rem;
    font-size: 30px;
  }

  .date {
    font-size: 20px;
    color: #f76c6f;
  }

  .place {
    font-size: 25px;
    color: #9b279d;
    width: fit-content;
    padding-right: 2rem;

    border-bottom: 1px solid #9b279d;
  }

  .status {
    padding: 8px 18px;
    border-radius: 25px;
    font-weight: bold;
    font-size: 20px;
    color: white;
  }

  .infos {
    background-color: #e0d8d8;
    font-size: 20px;
    padding: 0.5rem;

    // clip-path: polygon(0 0, 75% 0, 75% 100%, 0% 100%);
  }

  .pending {
    background-color: #f400f9;
  }
  .accepted {
    background-color: #12b82c;
  }
  .rejected {
    background-color: red;
  }

  .ellipsis {
    max-width: 75%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


//! ------------- Category Title --------------- |

.category-title {
  // margin: 5rem 0;
  text-align: center;
  a {
    font-size: 1.5rem;

    color: deeppink;
  }

}

//! --------------- Home page----------------- |



.homePage {
  text-align: center;
  margin: 0 1rem;
 
  h1 {
    font-size: 4rem;
    margin: 3rem 0 0 0;
  }

  hr {
    width: 50%;
    margin: 1rem auto 2rem;
  }

  h2 {
    margin-top: 2rem;
    text-align: center;
    font-family: "Courgette", sans-serif;
  }

  h3 {
    margin: 1rem;
    text-align: center;
    font-family: "Courgette", sans-serif;
  }
}

.homePage h2 {
  margin-left: 1rem;
}

.homePageBanner {
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 400px;
  margin-top: 80px;
  background-color: gray;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 50%;
}

.frame {
  height: 200px;
  width: 50%;
  background-color: red;
}

.homePageBannerCarousel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 75vh;
  display: grid;

  overflow: hidden;

  img {
    /* position: relative; */
    bottom: 20%;
    width: 100%;
    // animation: zoomIn 10s linear infinite;
  }

  button {
    margin: 0.5rem !important;
    margin-bottom: 3rem !important;
  }
  .react-multi-carousel-item--active {
    img {
      // display: none;
      animation: zoomIn 10s linear infinite alternate;
    }
  }
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.homePageBannerTitle {
  display: grid;
  place-items: center;
  z-index: 1;
  position: absolute;

  text-align: center;
  width: fit-content;
  padding: 0 3rem;
  height: 250px;
  border-radius: 10px;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  color: rgba(255, 255, 255, 0.85);
  font-size: 5rem;
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;
  /* background-color: hsla(358, 98%, 76%, 0.3); */
  background-color: rgba(123, 1, 5, 0.5);
  font-family: Playfair Display;
}

.HomePageDescription {
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem;
  font-family: "Courgette", sans-serif;
}

//! ------------- Post component --------------- |

.postComponent {
  position: relative;
  font-family: Roboto;
  line-height: 2rem;
  margin: 1rem;

  p{
    font-size: 1.2rem;
  }

  img {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 1rem;
  }
}

//! ------------ Category Carousel ------------- |

.categoryLink {
  * {
    transition: all 1s;
  }
  .link-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  margin: 1rem;
  .category-image {
    position: relative;
    img {
      height: 175px;
      width: 100%;
    }

    &:hover .link-button {
      opacity: 1;
      z-index: 100;
      transform: translate(-50%, -50%) scale(1);
    }

    &:hover img {
      filter: brightness(0.75);
    }
    &:after {
      content: "\e8b5";
      position: absolute;

      border: 2px solid white;
      inset: 0.5rem;
      bottom: 0.7rem;
    }
  }
}

//! ----------------- Footer ------------------- |

.footer {
  margin-top: 5rem;
  padding: 2rem;

  position: relative;
  border-top: 1px solid #000;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: "Courgette";

  h1 {
    color: hsl(150, 1%, 20%);
    font-size: 5rem;
  }

  h2 {
    font-size: 2rem;
  }
  .create-by {
    font-size: 2rem;

    // position: absolute;
    // right: 1rem;
    // bottom: 1rem;
  }
}

.footerLinks {
  display: flex;
  justify-content: center;
  text-align: center;
}

.socialImg {
  width: 50px;
  height: 50px;
}

//! ---------------- authPage ------------------ |

.authPage {
  position: relative;
  display: flex;
  min-height: calc(100vh - 80px);

  .auth-page-main-container {
    // transform: translateX(-8.5rem);

    animation: slidein 3s both;

    @keyframes slidein {
      from {
        transform: translateX(-8.5rem) scale(0);
      }

      to {
        transform: translateX(0) scale(1);
      }
    }
  }

  .bgImage {
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
    z-index: -100;
    filter: blur(2px);
    // transform: translateX(8.5rem);
  }

  .forget-password-title {
    font-size: 1.7rem;
    font-family: unset;
    text-align: center;
    margin: 1rem;
  }

  h1 {
    font-size: 2rem;
    font-family: Courgette;
  }
}

//! --------------- Add New Post ----------------- |

.addNewPost {
  // overflow: hidden;

  // height: 40vh;
  width: 50vw;

  h3 {
    text-align: center;
    margin: 1.5rem;
  }
}

//! ------------ Category details -------------- |
.viewAllPage {
  padding: 4rem;
}

.viewAllPageDescription {
  margin: 5rem 0;
  font-size: 20px;
  line-height: 1.7rem;
}


//! -------------- Favorite Page --------------- |

.favoritePage{
  padding: 3rem;
}

.likedPageGrid {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  row-gap: 3rem;
}

.favoriteBanner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 300px;
  margin-top: 80px;
  overflow: hidden;
}

.favoriteBanner img {

  width: 100%;
}

.favoriteBannerTitle {

margin: 3rem 0;

  font-size: 4rem;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;


}


//! ---------- Category Select Grid ------------ |

.categorySelectGrid{
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;

}


//! -------------- Waiting posts --------------- |
.waitingPostPage{
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 50px;
  height: 80vh;
}


.waitingPostDetails {
  height: fit-content;
  padding: 1rem;

  background-color:#FAFAFA !important;
}

.tableAlignCenter td, .tableAlignCenter th {
  text-align: center;

}

.tableAlignCenter svg {
  cursor: pointer;
}


//! -- FullScreen Edit Post Dashboard admin ---- |

.give-rating {

width: 100%;
font-size: 2rem;
margin-top: 1rem;

   button {
    font-size: 0.6em;
  }
}